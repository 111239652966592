import axios from 'axios';

const ELASTIC_API_URL = 'https://api.elasticemail.com/v2'
const ELASTIC_PUBLIC_ACCOUNT_ID = 'b627ec38-666a-476b-a905-f5c593384839'

export const subscribe = async (email: string) => {
  const params = new URLSearchParams();
  params.append('email', email);
  params.append('publicAccountID', ELASTIC_PUBLIC_ACCOUNT_ID);
  params.append('sendActivation', 'false');
  
  console.log(await axios.post(`${ELASTIC_API_URL}/contact/add`, params));
}