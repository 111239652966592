import React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';

import { theme } from '../../../styles';

interface Props {
  onClick: () => void
  style?: {
    [key: string]: string;
  };
}

const Button = styled.button<Props>`
  height: 77px;
  width: 100%;
  border: solid 2px;
  border-color: ${rgba(theme.colors.purple, 0.2)};
  border-radius: 38.5px;
  background-color: transparent;
  color: ${theme.colors.purple};
  font-family: ${theme.fonts.family};
  font-weight: ${theme.fontWeights.regular};
  font-size: ${theme.fontSize.normal};
  font-style: normal;
  cursor: pointer;
  @media screen and (min-width: 968px) {
    min-width: 240px;
    padding: 20px 80px;
    width: auto;
  }
  &:hover ,
  &:focus {
    background-color: ${rgba(theme.colors.purple, 0.1)}
  }
`;

export const ButtonSecondary: React.FC<Props> = ({
  onClick,
  style,
  children,
}) => (
    <Button style={style} onClick={onClick}>
      {children}
    </Button>
);
