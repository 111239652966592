import React, {
  useState,
} from 'react';
import styled from 'styled-components';
import { subscribe } from '../../services/newsletter'
import { theme } from '../../styles';

import { FooterContainer, ContentWrapper } from '../Containers'
import { TextInputWithSubmit } from '../'

const FooterInner = styled.div<{ alignment?: string }>`
    @media screen and (min-width: 968px)  {
        display: flex;
        justify-content: space-between;
        align-items: ${({ alignment }) => alignment || 'center'};
    }
`;

const EmailWrap = styled.div`
    height: 65px;
    padding-bottom: 15px;
`;

const StyledHeading = styled.p`
  color: ${theme.colors.white};
  font-family: ${theme.fonts.family};
  font-size: ${theme.fontSize.medium};
  margin: 10px 0;
  @media screen and (min-width: 968px) {
    margin: 40px 0 14px 0;
  }
`
const StyledParagraph = styled.p`
  color: ${theme.colors.white};
  font-family: ${theme.fonts.family};
  font-size: ${theme.fontSize.normal};
  opacity: 0.5;
  margin: 0;
  margin-bottom: 20px;
`

const StyledCopyright = styled.p`
    color: ${theme.colors.white};
    font-family: ${theme.fonts.family};
    font-size: ${theme.fontSize.normal};
    opacity: 0.3;
    margin: 48px 0;
    font-size: 0.75rem;
    position: absolute;
    align-self: flex-end;
    @media screen and (min-width: 968px)  {
        position: relative;
        margin: 0;
        font-size: 1rem;
    }
`

const validateEmail = (email: string): boolean => {
  const regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regEx.test(email.toLowerCase());
}

export const Footer: React.FC = ({
  children,
}) => {
  const [emailSubmitted, setEmailSubmitted] = useState(false);
  const handleSubmit = async (email: string): Promise<void> => {
    try {
      await subscribe(email);
      setEmailSubmitted(true);
    } catch(err) {
      console.error(err);
    }
  }
  return (
    <FooterContainer>
      <ContentWrapper>
          <StyledHeading>Sign up for mail.</StyledHeading>
          <FooterInner>
              <StyledParagraph>Keep up to date with Atik news!</StyledParagraph>
          </FooterInner>
          <FooterInner alignment="baseline">
              <EmailWrap>
                  {emailSubmitted ?
                    <StyledParagraph>Thanks for subscribing!</StyledParagraph>
                    :
                    <TextInputWithSubmit label="Email" validate={validateEmail} onSubmit={handleSubmit} />
                  }
              </EmailWrap>
              <StyledCopyright>{`© 2019 - ${(new Date()).getFullYear()} Atik Solutions Ltd. All rights reserved.`}</StyledCopyright>
          </FooterInner>        
      </ContentWrapper> 
    </FooterContainer>
  );
}
