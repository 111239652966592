import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import { rgba } from 'polished'

import { theme } from '../../styles';

interface Props {
  name?: string,
  style?: {
    [key: string]: string;
  };
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const CheckboxWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 43px;
`

const Input = styled.input.attrs(() => ({
  type: 'checkbox'
}))`
  position: absolute;
  opacity: 0;

  +Label::after {
      content: none;
  }
  
  :checked +Label::before {
    content: "";
    background-color: ${theme.colors.purple};
  }

  :checked +Label::after {
    content: "";
  }

  :focus + Label::before {
      outline: rgb(59, 153, 252) auto 5px;
  }
`

const Label = styled.label`
  position: relative;
  top: 0;
  left: 66px;
  font-family: ${theme.fonts.family};
  font-size: ${theme.fontSize.normal};
  font-weight: ${theme.fontWeights.regular};
  color: ${theme.colors.purple};

  ::before,
  ::after {
    content: "";
    display: inline-block;
    position: absolute;
  }

  ::before {
      height: 44px;
      width: 44px;
      border: 2px solid;
      background-color: transparent;
      cursor: pointer;
      
      left: -66px;
      @media screen and (max-width: 768px) {
        top: -18px;
      }
  }

  ::after {
      height: 8px;
      width: 17px;
      border-left: 2px solid;
      border-bottom: 2px solid;
      transform: rotate(-45deg);
      top: calc(16px - 16px);
      left: calc(15px - 66px);
      @media screen and (min-width: 768px) {
        top: 16px;
      }
      color: ${theme.colors.white}
  }
`

export const Checkbox: React.FC<Props> = (props) => {
  let id = uuidv4()
  return (
    <CheckboxWrapper style={props.style}>
      <Input 
        id={id} 
        name={props.name} 
        onChange={props.onChange ? props.onChange : () => {}}  
      />
      <Label htmlFor={id}>{props.children}</Label>
    </CheckboxWrapper>
  );
}
