import React from 'react';
import styled from 'styled-components';

import { theme } from '../../../styles'

import  { ReactComponent as Logo} from '../../../assets/icons/atik-footer-logo.svg'

const StyledContainer = styled.div`
  position: relative;
  width: 100%;
  height: 375px;
  background-color: ${theme.colors.purple};
`

const LogoWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
`

export const FooterContainer: React.FC = ({
  children
}) => (
  <StyledContainer>
    {children}
    <LogoWrapper>
      <Logo />
    </LogoWrapper>
  </StyledContainer>
);