import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

interface Props {
  children?: any;
  isDebug?: boolean;
  padding?: string;
}

const StyledWrapper = styled.div<Props>`
  margin: 0 auto;
  padding: ${({ padding }) => padding ? padding : '55px 27.5px'};
  max-width: 1050px;
  transition: 0.4s;
  ${({ isDebug }: { isDebug?: boolean }) => isDebug && 'background-color: orange;'}
`;

export const ContentWrapper = ({ children, ...props }: Props) => (
  <StyledWrapper {...props}>
    {children}
  </StyledWrapper>
)

ContentWrapper.propTypes = {
  children: PropTypes.node,
  isDebug: PropTypes.bool,
};

ContentWrapper.defaultProps = {
  children: null,
  isDebug: false,
};