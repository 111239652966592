export const theme = {
    colors: {
        purple: '#2e2677',
        darkPurple: '#1c183f',
        white: '#ffffff',
        lightBlue: '#d9eff4',
        lighterBlue: '#f0f9fb',
        turquoise: '#0096b3',
    },
    fontSize: {
        normal: '20px',
        large: '55px',
        medium: '28px',
        small: '16px',
    },
    fonts: {
        family: 'SpaceGrotesk, Arial' // Arial as a fallback
    },
    fontWeights: {
        bold: 700,
        regular: 400,
        medium: 500,
    },
    width: '1048px',
}