import React from 'react';
import styled from 'styled-components';

import { theme } from '../../styles';

import { HeroTitle } from '../'
import { ReactComponent as Pattern } from './hero-pattern.svg'

interface Props {
    height?: number;
    bgColor?: string;
    patternSpacing?: number;
}

const snapHeight = (height: number, patternSpacing: number): number => {
    var rawQuotient = height/patternSpacing;
    var remainder = rawQuotient % 1;
    var quotient = rawQuotient - remainder;

    return quotient * patternSpacing;
}

const Container = styled.div<Props>`
  position: relative;
  height: calc(${({ height, patternSpacing }) => snapHeight(height, patternSpacing)}px + 30px);
  background-color: ${({ bgColor }) => bgColor ? bgColor : theme.colors.purple};
`

const Wrapper = styled.div<Props>`
  position: absolute;
  bottom: 0;
  padding-top: 15px;
  padding-right: 5px;
  background-color: ${({ bgColor }) => bgColor ? bgColor : theme.colors.purple};
  width: 66%;
  max-width: 690px;
`

const MobileOnly = styled.div`
  @media screen and (min-width: 768px) {
    display: none;    
  }
`;

const DesktopOnly = styled.div`
  @media screen and (max-width: 768px) {
    display: none;    
  }
`;

export const Hero: React.FC<Props> = ({
  height,
  bgColor,
  patternSpacing,
  children
}) => {
    return (
      <>
        <DesktopOnly>
          <Container height={500} patternSpacing={57} background-color={bgColor}>
            <Pattern width="100%" height="100%" />
            <Wrapper background-color={bgColor}>
                <HeroTitle>{children}</HeroTitle>
            </Wrapper>
          </Container>
        </DesktopOnly>
        <MobileOnly>
          <Container>
            <HeroTitle>{children}</HeroTitle>
          </Container>
        </MobileOnly>
      </>
)};
