import React from 'react';
import styled from 'styled-components';
import { theme } from '../../styles';

interface Props {
  color?: string;
  style?: {
    [key: string]: string;
  }
}

const Paragraph = styled.p<Props>`
  margin: 0;
  padding: 0; 
  color: ${({ color }) => color ? color : theme.colors.purple};
  font-size: ${theme.fontSize.normal};
  font-family: ${theme.fonts.family};
`;

export const Text: React.FC<Props> = ({
  color,
  style,
  children,
}) => (
    <Paragraph color={color} style={style}>
      {children}
    </Paragraph>
);
