import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import { rgba } from 'polished'

import { theme } from '../../styles';

interface Props {
  label: string,
  name?: string,
  style?: {
    [key: string]: string;
  };
}

const Box =  styled.div`
  position: relative;
  @media screen and (min-width: 968px) {
    min-width: 330px;
  }
`

const Icon = styled.svg`
  position: absolute;
  top: 26px;
  right: 20px;
  transform: translateY(-50%);
  stroke: ${theme.colors.purple};
  opacity: 0.2;
`

const Label = styled.label`
  position: absolute;
  top: -15px;
  display: block;
  margin-bottom: 0;
  color: ${theme.colors.purple};
  font-family: ${theme.fonts.family};
  font-size: ${theme.fontSize.small};
  font-weight: ${theme.fontWeights.regular};
  font-style: normal;
  visibility: hidden;
`

const Input = styled.textarea`
  position: relative;
  min-height: 93px;
  top: 0;
  padding: 20px;
  width: calc(100% - 40px);
  resize: none;
  background: transparent;
  color: ${theme.colors.purple};
  font-family: ${theme.fonts.family};
  font-size: ${theme.fontSize.normal};
  font-weight: ${theme.fontWeights.regular};
  font-style: normal;
  border: none;
  border-bottom: 2px solid ${rgba(theme.colors.purple, 0.2)};

  ::placeholder {
    color: ${theme.colors.purple}
  }

  &:focus-within {
    outline: none;
    background: ${theme.colors.white};
    border-bottom: 2px solid ${rgba(theme.colors.purple, 1)};
    &::placeholder {
      color: transparent;
    };
    +Label {
      visibility: visible;
    };
    ~${Icon} {
      display: none;
    }
  }
  &:hover{
    border-bottom: 2px solid ${rgba(theme.colors.purple, 1)};
  }
`;


export const TextArea: React.FC<Props> = (props) => {
  let id = uuidv4()
  return (
    <>
      <Box style={props.style}>
        <Input id={id} placeholder={props.label} {...props}></Input>
        <Label htmlFor={id}>{props.label}</Label>
        <Icon version="1.1" width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 1L2 9L10 17" stroke-width="2"/>
        </Icon>
      </Box>
    </>
  );
}