import React from 'react';
import styled from 'styled-components';
import { theme } from '../../styles';

interface Props {
  link: string;
  selected: boolean;
  color?: string;
  style?: {
    [key: string]: string;
  };
}

const Link = styled.a<Props>`
  margin-left: 55px;
  padding: 0;
  color: ${theme.colors.white};
  opacity: ${({ selected }) => selected ? '1' : '0.7'};
  text-decoration: none;
  font-family: ${theme.fonts.family};
  line-height: 1.2;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`;

export const NavLink: React.FC<Props> = ({
  link,
  selected = false,
  style, 
  children, 
}) => (
  <Link 
    link={link}
    selected={selected}
    style={style}
  >
    {children}
  </Link>
);
