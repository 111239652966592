import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql, withPrefix, Link } from 'gatsby';
import CookieConsent from "react-cookie-consent";

// import Header from '../Header';
// import { Footer } from '../../sections/footer'
import {NavBar, NavLink, Footer} from '../'
import ResetStyle from '../Reset';
import { GlobalStyles } from '../../components';
import { NAV_ITEMS } from '../../constants';

import { theme } from '../../styles';


const Layout = ({ children }) => {
  // const navigationItems = NAV_ITEMS.map(({ to, href, label }) => {
  //   if (href) {
  //     return (
  //       <a key={label} href={href}>
  //         {label}
  //       </a>
  //     );
  //   }

  //   return (
  //     <Link key={label} to={to}>
  //       {label}
  //     </Link>
  //   );
  // });

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <ResetStyle />
          <GlobalStyles />
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[{ name: 'description', content: 'Sample' }, { name: 'keywords', content: 'sample, something' }]}
          >
            <html lang="en" />
          </Helmet>
          <NavBar>
            {/* <NavLink selected={false} link={'#'}>Home</NavLink> */}
            {/* <NavLink selected={false} link={'#'}>Knowledge Center</NavLink> */}
            {/* <NavLink selected={false} link={'#'}>FAQ</NavLink> */}
          </NavBar>
          {children}
          <Footer>
            {/* <NavLink selected={false} link={'#'}>Knowledge Center</NavLink> */}
            {/* <NavLink selected={false} link={'#'}>FAQ</NavLink> */}
            
            {/* TODO: Tawk intergration will be on every page, maybe should be decided on a per page basis */}
            {/* <script src={withPrefix('tawk.js')} type="text/javascript" /> */}
          </Footer>
          {/* <CookieConsent
            location="bottom"
            buttonText="I understand"
            cookieName="myAwesomeCookieName2"
            style={{ background: "#2B373B" }}
            buttonStyle={{ 
              borderRadius: "50px", 
              background: theme.colors.turquoise, 
              color: theme.colors.white, 
              fontWeight: theme.fontWeights.regular, 
              fontSize: theme.fontSize.small
            }}
            onAccept={() => console.log('ADD COOKIE LOGIC HERE')}
          >
            This website uses cookies to enhance the user experience.{" "}
          </CookieConsent> */}
        </>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
