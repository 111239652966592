import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import { rgba } from 'polished'

import { theme } from '../../styles';

import { ReactComponent  as Icon} from '../../assets/icons/chevron-left.svg';

interface Props {
  label: string,
  name?: string,
  type?: string,
  style?: {
    [key: string]: string;
  },
  validate?(value: string): boolean
  required?: boolean
}

const InputBoxWrapper =  styled.div`
  position: relative;
  @media screen and (min-width: 968px) {
    min-width: 330px;
  }
`
const StyledIcon = styled.div`
  position: absolute;
  top: 26px;
  right: 20px;
  transform: translateY(-50%);
  stroke: ${theme.colors.purple};
  opacity: 0.2;
`

const Label = styled.label`
  position: absolute;
  top: -15px;
  display: block;
  margin-bottom: 0;
  color: ${theme.colors.purple};
  font-family: ${theme.fonts.family};
  font-size: ${theme.fontSize.small};
  font-weight: ${theme.fontWeights.regular};
  font-style: normal;
  visibility: hidden;
`

const Input = styled.input.attrs((props) => ({
  type: props.type
}))`
  position: relative;
  top: 0;
  padding: 20px;
  width: calc(100% - 40px);
  background: transparent;
  color: ${theme.colors.purple};
  font-family: ${theme.fonts.family};
  font-size: ${theme.fontSize.normal};
  font-weight: ${theme.fontWeights.regular};
  font-style: normal;
  border: none;
  border-bottom: 2px solid ${rgba(theme.colors.purple, 0.2)};

  ::placeholder {
    color: ${theme.colors.purple}
  }

  &:focus-within {
    outline: none;
    background: ${theme.colors.white};
    border-bottom: 2px solid ${rgba(theme.colors.purple, 1)};
    &::placeholder {
      color: transparent;
    };
    +Label {
      visibility: visible;
    };
    ~${StyledIcon} {
      display: none;
    }
  }
  &:hover{
    border-bottom: 2px solid ${rgba(theme.colors.purple, 1)};
  }
`;

export const TextInput: React.FC<Props> = (props) => {
  let id = uuidv4()
  return (
      <InputBoxWrapper style={props.style}>
          <Input id={id} placeholder={props.label} {...props}/>
          <Label htmlFor={id}>{props.label}</Label>
          <StyledIcon>
            <Icon />
          </StyledIcon>
      </InputBoxWrapper>
  );
}