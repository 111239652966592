import React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished'

import { theme } from '../../styles';

import { ReactComponent as Icon } from '../../assets/icons/chevron-down.svg'

interface Props {
  label: string,
  name?: string,
  style?: {
    [key: string]: string;
  };
}

const SelectBoxWrapper = styled.div`
  position: relative;
`

const IconWrapper = styled.label.attrs(() => ({
  htmlFor: 'select-box'
}))`
  position: absolute;
  top: 26px;
  right: 20px;
  opacity: 0.2;
`

const SelectBox = styled.select.attrs(() => ({
  id: 'select-box'
}))`
  position: relative;
  display: block;
  cursor: pointer;
  font-family: ${theme.fonts.family};
  font-size: ${theme.fontSize.normal};
  color: ${theme.colors.purple};
  padding: 20px;
  width: 100%;
  
  box-sizing: border-box;
  background-color: transparent;
  margin: 0;
  border: none;
  border-bottom: 2px solid ${rgba(theme.colors.purple, 0.2)};
  -moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
  @media screen and (min-width: 968px) {
    min-width: 330px;
  }
  ::-ms-expand {
    display: none;
  }
  :hover {
    border-color: ${rgba(theme.colors.purple, 1)};
    ~${IconWrapper} {
      svg{
        opacity: 1;
      }
    }
  }
  :focus {
    background-color: ${theme.colors.white};
    +${IconWrapper} {
      display: none;
    }
  }
`

export const Select: React.FC<Props> = (props) => (
  <SelectBoxWrapper style={props.style}>
    <SelectBox {...props}>
      <option value="" disabled selected>{props.label}</option>
      {props.children}
    </SelectBox>
    <IconWrapper>
      <Icon />
    </IconWrapper>
  </SelectBoxWrapper>
);