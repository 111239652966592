import { useState, useEffect } from "react";
import _ from 'lodash';

enum ScrollDirection {
  Down = 'down',
  Up = 'up',
}

interface ScrollData {
  scrollX: number;
  scrollY: number;
  scrollDirection: ScrollDirection;
}

type SSRRect = {
  bottom: number
  height: number
  left: number
  right: number
  top: number
  width: number
  x: number
  y: number
}
const EmptySSRRect: SSRRect = {
  bottom: 0,
  height: 0,
  left: 0,
  right: 0,
  top: 0,
  width: 0,
  x: 0,
  y: 0,
}

export const useScroll = (callback: (data: ScrollData) => void) => {
  const [lastScrollTop, setLastScrollTop] = useState<number>(0)
  const [bodyOffset, setBodyOffset] = useState<DOMRect | SSRRect>(
    typeof window === 'undefined' || !window.document
      ? EmptySSRRect
      : document.body.getBoundingClientRect()
  )
  const [scrollY, setScrollY] = useState<number>(bodyOffset.top)
  const [scrollX, setScrollX] = useState<number>(bodyOffset.left)
  const [scrollDirection, setScrollDirection] = useState<ScrollDirection>(ScrollDirection.Down)

  const listener = () => {
    setBodyOffset(
      typeof window === 'undefined' || !window.document
        ? EmptySSRRect
        : document.body.getBoundingClientRect()
    )
    setScrollY(-bodyOffset.top)
    setScrollX(bodyOffset.left)
    setScrollDirection(lastScrollTop > -bodyOffset.top ? ScrollDirection.Down : ScrollDirection.Up)
    setLastScrollTop(-bodyOffset.top)
  }

  const debouncedListener = _.debounce(listener, 50, { leading: true, trailing: false });

  useEffect(() => {
    window.addEventListener('scroll', debouncedListener)
    return () => {
      window.removeEventListener('scroll', debouncedListener)
    }
  })

  callback({
    scrollY,
    scrollX,
    scrollDirection,
  });
}
