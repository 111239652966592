import React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';

import { theme } from '../../../styles';

interface Props {
  disabled: boolean;
  onClick?: () => void
  style?: {
    [key: string]: string;
  };
}

const Button = styled.button<Props>`
  height: 77px;
  min-width: 240px;
  padding: 20px 80px;
  border: none;
  border-radius: 38.5px;
  background-color: ${theme.colors.purple};
  color: ${theme.colors.lightBlue};
  font-family: ${theme.fonts.family};
  font-weight: ${theme.fontWeights.bold};
  font-size: ${theme.fontSize.normal};
  font-style: normal;
  cursor: pointer;
  &:hover ,
  &:focus {
    background-color: ${rgba(theme.colors.purple, 0.7)};
  }
  &:disabled {
    opacity: 0.3;
    &:hover {
      cursor: not-allowed;
    }
  }
`;

export const ButtonPrimary: React.FC<Props> = ({
  onClick,
  style,
  children,
  disabled = false,
}) => (
    <Button disabled={disabled} style={style} onClick={onClick}>
      {children}
    </Button>
);
