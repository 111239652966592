import React, { useState } from 'react';
import styled from 'styled-components';

import { theme } from '../../styles';

interface Props {
    title: string,
    name?: string,
    method?: string,
    children?: any,
}

const Title = styled.p`
    font-family: ${theme.fonts.family};
    font-size: ${theme.fontSize.medium};
    font-weight: ${theme.fontWeights.bold};
    color: ${theme.colors.purple};
    margin: 0 0 25px;
    line-height: 39px;
    @media screen and (min-width: 968px) {
      margin-bottom: 0;
      grid-column: 1 / 3;
    }
`

const StyledForm = styled.form`
    @media screen and (max-width: 968px) {
      input, select, textarea {
        margin-bottom: 25px;
      }
    }
    @media screen and (min-width: 968px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 55px 30px;
      max-width: 690px;
    }
`

export const Form: React.FC<Props> = (props) => {
  return (
    <StyledForm {...props}>
      <Title>{props.title}</Title>
      {props.children}
    </StyledForm>
  )
}