import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

interface Props {
  bgColor: string
  style?: {
    [key: string]: string;
  };
}

const StyledContainer = styled.div<Props>`
  width: 100%;
  background-color: ${({ bgColor }) => bgColor ? bgColor : 'transparent'};
`

export const SectionContainer: React.FC<Props> = ({
  bgColor,
  style,
  children
}) => (
  <StyledContainer bgColor={bgColor} style={style}>
    {children}
  </StyledContainer>
);