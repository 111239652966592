import React, {
  useState,
} from 'react';
import styled from 'styled-components';
import { theme } from '../../styles';
import { useScroll } from '../../hooks';
import { ContentWrapper } from '../Containers'

import { ReactComponent  as Logo} from '../../assets/icons/atik-logo.svg';

const OuterWrap = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  background-color: ${theme.colors.purple};
  z-index: 99;
`;

const NavSizer = styled.div`
  background-color: ${theme.colors.purple};
  height: 160px;
`;

const NavWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
  background-color: ${theme.colors.purple};
`

const NavBarInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  margin: 0 auto;
`;

export const NavBar: React.FC = ({
  children,
}) => {
  const [small, setSmall] = useState(false);
  useScroll((data) => {
    const HEIGHT = 20;
    const { scrollY } = data;
    if (small && scrollY < HEIGHT) {
      setSmall(false);
    } else if (!small && scrollY > HEIGHT) {
      setSmall(true);
    }
  });
  return(
    <>
      <OuterWrap>
        <NavWrapper>
          <ContentWrapper padding={small ? '10px 27.5px' : undefined}>
            <NavBarInner>
              <Logo />
              <div>
                {children}
              </div>
            </NavBarInner>
          </ContentWrapper>
        </NavWrapper>
      </OuterWrap>
      <NavSizer></NavSizer>
    </>
  );
}