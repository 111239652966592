import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';

import { theme } from '../../styles';

import { ReactComponent  as IconInput} from './chevron-left.svg';
import { ReactComponent  as IconSubmit} from './submit.svg';


interface Props {
  label: string,
  validate?(value: string): boolean
}

const Form =  styled.form`
  position: relative;
  max-width: 330px;
  margin: 0;
`

const Label = styled.label`
  position: absolute;
  top: 0;
  display: block;
  margin-bottom: 7px;
  color: ${theme.colors.white};
  font-family: ${theme.fonts.family};
  font-size: ${theme.fontSize.small};
  font-weight: ${theme.fontWeights.regular};
  font-style: normal;
  visibility: hidden;
`

const InvisiButton = styled.button`
  border: none;
  background-color: transparent;
  border-radius: 25px;
  padding: 0;
  position: relative;
  left: 19px;
  &:hover {
    cursor: pointer;
  }
`;

const Input = styled.input.attrs(() => ({
  type: 'text'
}))`
  width: 90%;
  position: relative;
  top: 15px;
  padding: 20px;
  background: ${theme.colors.darkPurple};
  color: ${theme.colors.white};
  font-family: ${theme.fonts.family};
  font-size: ${theme.fontSize.normal};
  font-weight: ${theme.fontWeights.regular};
  font-style: normal;
  border: none;
  border-bottom: 2px solid ${theme.colors.turquoise};

  @media screen and (min-width: 768px) {
    padding: 20px;
  }

  ::placeholder {
    color: ${theme.colors.white}
  }

  &:focus-within {
    outline: none;
    &::placeholder {
      color: transparent;
    };
    +Label {
      visibility: visible;
    };
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 36px;
  right: 20px;
`

const Submit = styled.label`
  position: absolute;
  top: 28px;
  right: 0;
  transform: translateX(-50%);

  input {
    display: none;
  }
`


export const TextInputWithSubmit: React.FC<Props> = ({
  label,
  validate
}) => {
  const [value, setValue] = useState('');
  const [isValid, setIsValid] = useState(false);
  let id = uuidv4()
  let Icon: JSX.Element

  (!validate) ? validate = (value: string) => (value != '') : null

  if (isValid) {
    Icon = (
      <Submit>
        <InvisiButton type="submit">
          <IconSubmit />
        </InvisiButton>
      </Submit>
    )
  } else {
    Icon = (
      <IconWrapper>
        <IconInput />
      </IconWrapper>
    )
  }  

  return (
    <>
      <Form 
        name="newsletter" 
        method="post" 
        data-netlify="true" 
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="newsletter" />
        <Input 
          id={id}
          placeholder={label} 
          onChange={(e) => {
            setIsValid(validate(e.target.value))
            setValue(e.target.value)
          }}
          name="email"
        />
        <Label htmlFor={id}>{label}</Label>
        
        {Icon}
      </Form>
    </>
  )
}
